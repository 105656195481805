import Head from "next/head";
import { Button, buttonVariants } from "@trident/ui/button";
import Link from "next/link";
import type { StaticImageData } from "next/image";
import Image from "next/image";
import logo from "../../../../public/images/logo.svg";
import WelcomeLaptop from "../../../../public/images/welcome-laptop.svg";
import { useAuth } from "@/features/providers/auth2";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@trident/ui/tooltip";
import { ScrollArea } from "@trident/ui/scroll-area";

export default function TridentLanding() {
  return (
    <>
      <Head>
        <title>AnchorWatch - Trident Vault</title>
        <meta name="AnchorWatch Trident Vault" content="AnchorWatch" />
      </Head>
      <ScrollArea className="h-screen flex-1 bg-grayscale-1">
        <LandingPageNav />
        <LandingPageContent />
      </ScrollArea>
    </>
  );
}

function LandingPageContent() {
  const { logout, session } = useAuth();

  return (
    <div className="z-50 flex h-screen w-full flex-col items-center justify-center gap-2">
      <div
        className={
          "flex h-fit w-full max-w-[1050px] flex-col items-center justify-center border-2 border-solid border-grayscale-6 bg-grayscale-white shadow-[0px_0px_33px_rgba(0,0,0,0.09)]"
        }
      >
        <div className="grid h-[48px] w-full grid-cols-[44px_1fr_44px] place-items-center items-center"></div>
        <div className="box-border flex h-[50px] w-full items-center border-y-2 border-y-grayscale-6 bg-[#F0F1F1] px-6 font-mono text-xl font-medium uppercase text-dark-teal-2">
          Trident
        </div>
        <div className="container m-auto h-full min-h-[490px] 2xl:min-h-[525px]">
          <div className="flex h-full flex-col justify-between gap-6 py-6">
            <h1 className="text-xl font-medium leading-6 tracking-tight">
              You need to log in to access Trident. If you don&apos;t have an
              account, please reach out to an agent.
            </h1>
            <Image
              src={WelcomeLaptop}
              alt="Welcome Laptop"
              priority
              quality={100}
              className="h-72 w-auto 2xl:h-auto"
            />
            <div className="flex gap-4">
              {session ? (
                <Button
                  variant="default"
                  onClick={() => logout()}
                  className="h-[50px] text-base"
                >
                  Logout
                </Button>
              ) : (
                <Link
                  className={buttonVariants({
                    className: "h-[50px] text-base",
                    variant: "default",
                  })}
                  href="/auth/signin"
                >
                  Log in
                </Link>
              )}

              <TooltipProvider>
                <Tooltip delayDuration={200}>
                  <TooltipTrigger
                    className={buttonVariants({
                      className: "h-[50px] text-base",
                      variant: "secondary",
                    })}
                  >
                    Contact Agent
                  </TooltipTrigger>
                  <TooltipContent
                    align="start"
                    className="max-w-[320px] rounded border-[1px] border-solid border-dark-teal-5 bg-white p-[10px] text-sm text-dark-teal-5"
                  >
                    Email us:
                    <strong> agent@anchorwatch.com</strong>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function LandingPageNav() {
  const { session } = useAuth();

  return (
    <header className="z-40 w-full bg-grayscale-2 font-mono backdrop-blur">
      <div className="flex h-[80px] w-full items-center justify-between px-6">
        <div className="flex items-center gap-10">
          <div className="mr-4 flex items-center space-x-4 lg:mr-6">
            <Image
              src={logo as StaticImageData}
              alt="Trident Logo"
              className="size-6"
            />
            <h1 className="inline-block font-mono text-[20px] font-medium uppercase leading-6">
              Trident
            </h1>
          </div>
          {session && (
            <Link
              href="/vaults"
              className="text-light-teal-3 transition-colors hover:text-yellow-2/80"
            >
              Vaults
            </Link>
          )}
        </div>

        <nav className="hidden items-center gap-8 text-sm uppercase md:flex">
          <AgentInfo />
        </nav>
      </div>
    </header>
  );
}

function AgentInfo() {
  return (
    <div className="flex items-center gap-2">
      <TooltipProvider>
        <Tooltip delayDuration={200}>
          <TooltipTrigger>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              className="cursor-pointer"
            >
              <path
                d="M17.8557 0.712891H2.14146C1.76258 0.712891 1.39922 0.8634 1.13131 1.13131C0.8634 1.39922 0.712891 1.76258 0.712891 2.14146V14.2843C0.712891 14.6632 0.8634 15.0266 1.13131 15.2945C1.39922 15.5624 1.76258 15.7129 2.14146 15.7129H4.99861V19.2843L9.45575 15.7129H17.8557C18.2346 15.7129 18.598 15.5624 18.8659 15.2945C19.1338 15.0266 19.2843 14.6632 19.2843 14.2843V2.14146C19.2843 1.76258 19.1338 1.39922 18.8659 1.13131C18.598 0.8634 18.2346 0.712891 17.8557 0.712891Z"
                stroke="#147C83"
                strokeWidth="1.43017"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.85547 6.42801C7.85547 6.0042 7.98115 5.5899 8.21661 5.23751C8.45207 4.88512 8.78673 4.61046 9.17829 4.44827C9.56985 4.28608 10.0007 4.24365 10.4164 4.32633C10.832 4.40901 11.2139 4.6131 11.5136 4.91279C11.8132 5.21247 12.0173 5.59429 12.1 6.00996C12.1827 6.42564 12.1403 6.85649 11.9781 7.24805C11.8159 7.63961 11.5412 7.97427 11.1888 8.20973C10.8364 8.44519 10.4221 8.57087 9.99833 8.57087V9.28516"
                stroke="#147C83"
                strokeWidth="1.43017"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.99721 11.4277C9.7853 11.4277 9.57815 11.4906 9.40196 11.6083C9.22576 11.726 9.08843 11.8934 9.00734 12.0891C8.92625 12.2849 8.90503 12.5004 8.94637 12.7082C8.98771 12.916 9.08975 13.1069 9.2396 13.2568C9.38944 13.4066 9.58035 13.5087 9.78818 13.55C9.99602 13.5913 10.2115 13.5701 10.4072 13.489C10.603 13.4079 10.7703 13.2706 10.8881 13.0944C11.0058 12.9182 11.0686 12.7111 11.0686 12.4992C11.0649 12.2162 10.9509 11.9458 10.7507 11.7456C10.5506 11.5455 10.2802 11.4314 9.99721 11.4277Z"
                fill="#147C83"
              />
            </svg>
          </TooltipTrigger>
          <TooltipContent
            align="end"
            className="max-w-[320px] rounded border-[1px] border-solid border-dark-teal-5 bg-white p-[10px] text-sm normal-case text-dark-teal-5"
          >
            Email us:
            <strong> agent@anchorwatch.com</strong>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}
